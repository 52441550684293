import React, {useEffect} from 'react';
import {useFormikContext} from "formik";

function AutoSubmit() {
  /*
    This component is used to automatically submit the form when the form is valid
    and has been changed(dirty).
   */

  const {isValid, values, dirty, submitForm} = useFormikContext()

  useEffect(() => {
    if(isValid && dirty){
      void submitForm()
    }
  }, [isValid, values, dirty, submitForm])
    return <></>
}


export default AutoSubmit
