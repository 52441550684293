/**
 * Meterpoint.io
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * An enumeration.
 */
export type EventTypes = 'CREATE' | 'UPDATE' | 'LINK' | 'UNLINK' | 'DELETE' | 'ASSET_SYNC_CONFIG_START' | 'ASSET_SYNC_CONFIG_SUCCEEDED' | 'ASSET_SYNC_CONFIG_FAILED' | 'ASSET_SYNC_CONFIG_ERROR' | 'ASSET_SYNC_CONFIG_INFO' | 'ASSET_SYNC_CONFIG_FINISHED' | 'ASSET_FIRMWARE_UPDATE_SUCCEEDED' | 'ASSET_FIRMWARE_UPDATE_FAILED' | 'ASSET_ASSIGN_TO_COMPANY' | 'ASSET_STATE_CHANGE' | 'ASSET_STATE_CHANGE_REQUEST' | 'ASSET_STATE_CHANGE_START' | 'ASSET_STATE_CHANGE_ERROR' | 'ASSET_STATE_CHANGE_SUCCEEDED' | 'ASSET_SEND_SMS' | 'ASSET_SEND_TELNET' | 'ASSET_MISSING_DATA_IDENTIFIED' | 'ASSET_MISSING_DATA_REQUESTED' | 'ALARM_OPENED' | 'ALARM_CLOSED' | 'JOB_CREATED' | 'JOB_SCHEDULED' | 'JOB_INFO' | 'JOB_ERROR' | 'JOB_COMPLETED' | 'JOB_FAILED' | 'REPORT_CREATED' | 'REPORT_SCHEDULED' | 'REPORT_STARTED' | 'REPORT_COMPLETED' | 'REPORT_ERROR' | 'REPORT_FAILED' | 'UPDATE_CREATED' | 'UPDATE_INFO' | 'UPDATE_ERROR' | 'UPDATE_COMPLETED' | 'UPDATE_FAILED';

export const EventTypes = {
    CREATE: 'CREATE' as EventTypes,
    UPDATE: 'UPDATE' as EventTypes,
    LINK: 'LINK' as EventTypes,
    UNLINK: 'UNLINK' as EventTypes,
    DELETE: 'DELETE' as EventTypes,
    ASSETSYNCCONFIGSTART: 'ASSET_SYNC_CONFIG_START' as EventTypes,
    ASSETSYNCCONFIGSUCCEEDED: 'ASSET_SYNC_CONFIG_SUCCEEDED' as EventTypes,
    ASSETSYNCCONFIGFAILED: 'ASSET_SYNC_CONFIG_FAILED' as EventTypes,
    ASSETSYNCCONFIGERROR: 'ASSET_SYNC_CONFIG_ERROR' as EventTypes,
    ASSETSYNCCONFIGINFO: 'ASSET_SYNC_CONFIG_INFO' as EventTypes,
    ASSETSYNCCONFIGFINISHED: 'ASSET_SYNC_CONFIG_FINISHED' as EventTypes,
    ASSETFIRMWAREUPDATESUCCEEDED: 'ASSET_FIRMWARE_UPDATE_SUCCEEDED' as EventTypes,
    ASSETFIRMWAREUPDATEFAILED: 'ASSET_FIRMWARE_UPDATE_FAILED' as EventTypes,
    ASSETASSIGNTOCOMPANY: 'ASSET_ASSIGN_TO_COMPANY' as EventTypes,
    ASSETSTATECHANGE: 'ASSET_STATE_CHANGE' as EventTypes,
    ASSETSTATECHANGEREQUEST: 'ASSET_STATE_CHANGE_REQUEST' as EventTypes,
    ASSETSTATECHANGESTART: 'ASSET_STATE_CHANGE_START' as EventTypes,
    ASSETSTATECHANGEERROR: 'ASSET_STATE_CHANGE_ERROR' as EventTypes,
    ASSETSTATECHANGESUCCEEDED: 'ASSET_STATE_CHANGE_SUCCEEDED' as EventTypes,
    ASSETSENDSMS: 'ASSET_SEND_SMS' as EventTypes,
    ASSETSENDTELNET: 'ASSET_SEND_TELNET' as EventTypes,
    ASSETMISSINGDATAIDENTIFIED: 'ASSET_MISSING_DATA_IDENTIFIED' as EventTypes,
    ASSETMISSINGDATAREQUESTED: 'ASSET_MISSING_DATA_REQUESTED' as EventTypes,
    ALARMOPENED: 'ALARM_OPENED' as EventTypes,
    ALARMCLOSED: 'ALARM_CLOSED' as EventTypes,
    JOBCREATED: 'JOB_CREATED' as EventTypes,
    JOBSCHEDULED: 'JOB_SCHEDULED' as EventTypes,
    JOBINFO: 'JOB_INFO' as EventTypes,
    JOBERROR: 'JOB_ERROR' as EventTypes,
    JOBCOMPLETED: 'JOB_COMPLETED' as EventTypes,
    JOBFAILED: 'JOB_FAILED' as EventTypes,
    REPORTCREATED: 'REPORT_CREATED' as EventTypes,
    REPORTSCHEDULED: 'REPORT_SCHEDULED' as EventTypes,
    REPORTSTARTED: 'REPORT_STARTED' as EventTypes,
    REPORTCOMPLETED: 'REPORT_COMPLETED' as EventTypes,
    REPORTERROR: 'REPORT_ERROR' as EventTypes,
    REPORTFAILED: 'REPORT_FAILED' as EventTypes,
    UPDATECREATED: 'UPDATE_CREATED' as EventTypes,
    UPDATEINFO: 'UPDATE_INFO' as EventTypes,
    UPDATEERROR: 'UPDATE_ERROR' as EventTypes,
    UPDATECOMPLETED: 'UPDATE_COMPLETED' as EventTypes,
    UPDATEFAILED: 'UPDATE_FAILED' as EventTypes
};