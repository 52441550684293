import Amplify from "aws-amplify";
import * as Sentry from "@sentry/react";
import Qs from "qs";
import axios, { AxiosRequestConfig } from "axios";
import { getAuthHeader } from "./actions/helpers/auth";
import { configure } from "axios-hooks";

declare global {
  interface Window {
    runConfig?: {
      apiUrl: string;
      webSocketUrl: string;
      apiUrl2100: string;
      sftpServer: string;
      cognitoPool: string;
      cognitoClient: string;
      region: string;
      environment: string;
      release: string;
      sentry?: {
        dsn: string
      }
    }  }
}
export const getApiUrl = (): string => {
  return process.env.REACT_APP_API_URL
    ? process.env.REACT_APP_API_URL
    : window.runConfig
    ? window.runConfig.apiUrl
    : "";
};

export const getCognitoConfig = () => {

  return {
    REGION: window.runConfig ? window.runConfig.region : "",
    USER_POOL_ID: process.env.REACT_APP_COGNITO_POOL_ID || (window.runConfig ? window.runConfig.cognitoPool : ""),
    APP_CLIENT_ID: process.env.REACT_APP_COGNITO_CLIENT_ID || (window.runConfig ? window.runConfig.cognitoClient : ""),
  }
}

export const getWebsocketBaseUrl = (): string => {
  return process.env.REACT_APP_WEBSOCKET_URL || (window.runConfig ? window.runConfig.webSocketUrl : '')
}

const config = {
  apiGateway: {
    REGION: window.runConfig ? window.runConfig.region : "",
    URL: getApiUrl(),
  },
  cognito: getCognitoConfig(),
};


export const configureSentry = (browserTracing: any): void => {
  Sentry.init({
    dsn: window.runConfig?.sentry?.dsn,
    ignoreErrors: ["Non-Error promise rejection captured"],
    environment: window.runConfig?.environment,
    release: window.runConfig?.release,
    integrations: [browserTracing],
    tracesSampleRate: 1.0,
  });
};
export const configureAmplify = (): void => {
  Amplify.configure({
    Auth: {
      mandatorySignIn: true,
      region: config.cognito.REGION,
      userPoolId: config.cognito.USER_POOL_ID,
      userPoolWebClientId: config.cognito.APP_CLIENT_ID,
      authenticationFlowType: "USER_PASSWORD_AUTH",
    },
    API: {
      endpoints: [
        {
          name: "management",
          endpoint: config.apiGateway.URL,
          region: config.apiGateway.REGION,
        },
      ],
    },
  });
};

export const paramsSerializer = (params: any) =>
  Qs.stringify(params, { arrayFormat: "repeat" });

export const requestInterceptor = async (config: AxiosRequestConfig) => {
  const headers = await getAuthHeader();
  return { ...config, headers: { ...config.headers, ...headers } };
};

axios.defaults.paramsSerializer = paramsSerializer;
axios.interceptors.request.use(requestInterceptor);

configure({ cache: false });

export default config;
