import React from 'react';
import {GatewayAsset} from "../../../openapi/model/gatewayAsset";
import {Form, Formik} from "formik";
import {Button, Col, Row} from "reactstrap";
import NewTextFormComponent from "../../../components/Forms/NewTextFormComponent";
import {BasicAuthCredentials} from "../../../openapi/model/basicAuthCredentials";
import {PublicKeyCredentials} from "../../../openapi/model/publicKeyCredentials";
import useUpdateEntity from "../../../hooks/updateEntity";
import BlockSpinner from "../../../components/Spinners/BlockSpinner";
import {ErrorBanner} from "../../../components/Error/ErrorBanner";


interface connectivitySettings {
    postUrl: string;
    sftpServer: string;
    username: string;
    password?: string;
    authenticationType: 'basic_auth' | 'public_key';
    publicKey?: string;
}

function isCredentialsBasic(credentials: BasicAuthCredentials | PublicKeyCredentials): credentials is BasicAuthCredentials {
    return (credentials as BasicAuthCredentials).type === 'basic_auth'
}

function isCredentialsPublicKey(credentials: BasicAuthCredentials | PublicKeyCredentials): credentials is PublicKeyCredentials {
    return (credentials as PublicKeyCredentials).type === 'public_key'
}

function GatewayConnectionDisplay(props: { gateway: GatewayAsset }) {
    const {gateway} = props;

    const {updating, updateEntity, error} = useUpdateEntity("ASSET", gateway.assetId)

    const initialValues: connectivitySettings = {
        postUrl: (gateway.model
            ? gateway.model?.indexOf("21") === -1
                ? `https://cme.hes.meterpoint.io/data/${gateway.companyId}`
                : window.runConfig && `${window.runConfig.apiUrl2100}/integrations/elvaco/${gateway.companyId}`
            : `https://cme.hes.meterpoint.io/data/${gateway.companyId}`) || 'unknown',
        sftpServer: (window.runConfig && window.runConfig.sftpServer) || 'unknown',
        username: gateway.credentials.username || '',
        password: isCredentialsBasic(gateway.credentials) ? gateway.credentials.password : undefined,
        publicKey: isCredentialsPublicKey(gateway.credentials) ? gateway.credentials.publicKey : undefined,
        authenticationType: gateway.credentials.type as 'basic_auth' | 'public_key'
    }

    const basicAuth: boolean = gateway.credentials.type ==='basic_auth';

    const submit = (formValues: connectivitySettings) => {
        console.log(gateway.credentials.type)
        console.log(basicAuth)
        console.log('update')
        if (basicAuth) {
            throw new Error('Can only update SSH Key')
        } else {
            updateEntity({
                ...gateway,
                credentials: {...gateway.credentials, publicKey: formValues.publicKey}
            })
        }

    }
    return (
        <>
            <h3>Gateway Connectivity Settings</h3>

            <Formik initialValues={initialValues} onSubmit={submit}>{() =>
                <Form>
                    <BlockSpinner loading={updating}>
                <Row>

                    <Col xs={12}>
                        {basicAuth ? <NewTextFormComponent fieldName={'postUrl'} label={'Post URL'}
                                              staticValue disabled clipboard/> :
                            <NewTextFormComponent fieldName={'sftpServer'} label={'SFTP Server'}
                                              staticValue disabled clipboard/>
                        }
                        <NewTextFormComponent fieldName={'username'} label={'Username'}
                                              staticValue disabled clipboard/>
                        {
                            basicAuth ?
                                <NewTextFormComponent fieldName={'password'} label={'Password'}
                                              staticValue disabled clipboard/>
                                :
                                <NewTextFormComponent fieldName={'publicKey'} label={'SSH Public Key'}/>
                        }
                    </Col>
                </Row>
                        {
                            !basicAuth && <Row>
                            <Col xs={12}>
                                              <span className={"float-right"}>

                                <Button type={'submit'} color={'primary'} disabled={updating}>Update SSH Key</Button>
                                              </span> </Col>
                            <ErrorBanner error={error}/>
                            </Row>
                        }
                    </BlockSpinner>
                </Form>
            }
            </Formik>
        </>
    );
}

export default GatewayConnectionDisplay;