import React from 'react';
import useGetEntity from "../../hooks/getEntity";
import {useSelectedCompany} from "../../reducers/company";
import {useSelectedScheme} from "../../reducers/scheme";
import {ErrorBanner} from "../../components/Error/ErrorBanner";
import BlockSpinner from "../../components/Spinners/BlockSpinner";
import {Table} from 'reactstrap';
import {DeviceConfigurationMap} from "../../openapi/model/deviceConfigurationMap";
import {formatDate} from "../../components/utils/HighlightNullAndOldDates";
import {faCheck, faXmark} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

interface AssetConfigurationPageProps {
    asset_id: string;
}

export default function AssetConfigurationPage(props: AssetConfigurationPageProps): React.ReactElement {

    const company = useSelectedCompany();
    const scheme = useSelectedScheme();

    const [{data, loading, error}] = useGetEntity<DeviceConfigurationMap>({
        entityType: "DETAILED_ASSET_CONFIGURATION",
        entityId: props.asset_id,
        companyId: company?.companyId,
        schemeId: scheme?.schemeId,
        singletonApi: true,
        path: `asset/${props.asset_id}/configuration/detailed`,
    })
    return (
        <BlockSpinner loading={loading}>
            <>
                {error && <ErrorBanner error={error}/>}
                <Table size={'sm'} striped={true}>
                    <thead>
                    <tr>
                        <th>Key</th>
                        <th>Target</th>
                        <th>Current</th>
                        <th>Last change</th>
                    </tr>
                    </thead>
                    <tbody>
                    {data?.values.map((value, index) => (
                        <tr key={index}>
                            <td>{value.key}</td>
                            <td>{value.target || "-"}</td>
                            <td>
                                {value.current || '-'}
                                {value.target && value.current &&
                                (value.target === value.current
                                    ? <FontAwesomeIcon icon={faCheck} className={'px-2 text-success'} /> :
                                    <FontAwesomeIcon icon={faXmark} className={'px-2 text-danger'} />
                                )}
                            </td>
                            <td><span className={'text-nowrap'}>{formatDate(value.lastChanged)}</span></td>
                        </tr>
                    ))}
                    </tbody>
                </Table>
            </>
        </BlockSpinner>
    );
}
