import React from "react";
import {Col, Row} from "reactstrap";
import BetaAlert from "../../../components/Beta/BetaAlert";
import {OneCardPage} from "../../../components/utils/OneCardPage";
import RequireScheme from "../../../components/Scheme/RequireScheme";
import SyntheticVolumeReportPage from "./SyntheticVolumeReportPage";
import RoutedTabGroupInterface from "../../../components/TabGroup/RoutedTabGroupInterface";
import SyntheticEnergyReportPage from "./SyntheticEnergyReportPage";
import RunTimeReportPage from "./RunTimeReportPage";
import VWARTReportContainer from "../vwartReport/VWARTReportContainer";
import {VWADTReportContainer} from "../vwadtReport/VWADTReportContainer";
import HeatNetworkQueryUrlWrapper from "./HeatNetworkQueryUrlWrapper";
import {HeatNetworkReportParams} from "./model";
import HeatNetworkReportQueryForm from "./HeatNetworkReportQueryForm";
import moment from "moment/moment";
import {AssetUseCase} from "../../../openapi/model/assetUseCase";
import EnergyBalanceReportPage from "../EnergyBalanceReportPage";
import {useParams} from "react-router-dom";


const HeatNetworkAnalysisReportPage = (props: {
    companyId?: string;
    schemeId?: string;
}): JSX.Element => {
      const params = useParams();
      const tab: string = params['tab'] ? params['tab'] : 'vwart';
    return (
        <OneCardPage
            headerTitle={"Heat Network Analysis"}
            breadcrumbs={[
                {url: "/admin/report/heat_network", name: "Heat Network Analysis"},
            ]}
        >
            <RequireScheme>
                <Row>
                    <Col xs={12}>
                        <BetaAlert/>
                    </Col>
                    <HeatNetworkQueryUrlWrapper defaultValues={{
                        startDate: moment().startOf('month').subtract(12, 'months'),
                        endDate: moment().startOf('month').subtract(1,'seconds'),
                        useCases: [AssetUseCase.DOMESTIC, AssetUseCase.NONE]
                    }}>
                        {
                            (
                                params: HeatNetworkReportParams,
                                onChange: (v: HeatNetworkReportParams) => void
                            ) => {
                                return <>
                                    <Col xs={12}>
                                        <HeatNetworkReportQueryForm
                                            onSubmit={(v) => {
                                                onChange(v)
                                                return Promise.resolve()
                                            }}
                                            initialValues={params}
                                            dateOnly={tab === 'energy_balance'}
                                        />
                                    </Col>
                                    <Col xs={12}>
                                        <RoutedTabGroupInterface
                                            tabs={[
                                                {
                                                    tabName: "VWART",
                                                    tabPath: "vwart",
                                                    tabContent: <VWARTReportContainer query={params} {...props}/>,
                                                },
                                                {
                                                    tabName: 'VWADT',
                                                    tabPath: 'vwadt',
                                                    tabContent: <VWADTReportContainer query={params} {...props}/>,
                                                },
                                                {
                                                    tabName: 'Energy Balance',
                                                    tabPath: 'energy_balance',
                                                    tabContent: <EnergyBalanceReportPage query={params} {...props}/>
                                                },
                                                {
                                                    tabName: "Volume",
                                                    tabPath: "volume",
                                                    tabContent: <SyntheticVolumeReportPage query={params} {...props}/>,
                                                },
                                                {
                                                    tabName: "Energy",
                                                    tabPath: "energy",
                                                    tabContent: <SyntheticEnergyReportPage query={params} {...props}/>,
                                                },
                                                {
                                                    tabName: "Runtime",
                                                    tabPath: "runtime",
                                                    tabContent: <RunTimeReportPage query={params} {...props}/>,
                                                },
                                            ]}
                                            topLevelPath={"/admin/report/heat_network/:tab"}
                                        />
                                    </Col>
                                </>
                            }
                        }
                    </HeatNetworkQueryUrlWrapper>

                </Row>
            </RequireScheme>
        </OneCardPage>
    );
};

export default HeatNetworkAnalysisReportPage;
