import React from 'react';
import {AssetPosition} from "../../../openapi/model/assetPosition";
import DataProcessingConfigurationForm from "../DataProcessingConfigurationForm";
import {Form, Formik} from "formik";
import {AssetPositionConfiguration} from "../../../openapi/model/assetPositionConfiguration";
import {Button, Col, Row} from "reactstrap";
import useUpdateEntity from "../../../hooks/updateEntity";
import {ErrorBanner} from "../../../components/Error/ErrorBanner";
import BlockSpinner from "../../../components/Spinners/BlockSpinner";

interface AssetPositionConfigurationTabProps {
    assetPosition: AssetPosition;
    refresh?: () => any;
}


interface FormValues {
    dataProcessing?: { autoCreateNewAssets: string }
}

function assetPositionToFormValues(assetPosition: AssetPosition): FormValues {
    return {
        dataProcessing: {autoCreateNewAssets: assetPosition.configuration?.dataProcessing?.autoCreateNewAssets?.toString() || 'true'}
    }
}

function formValuesToConfiguration(values: FormValues): AssetPositionConfiguration {
    return {
        dataProcessing: {autoCreateNewAssets: (values.dataProcessing?.autoCreateNewAssets !== 'false')}
    }
}

function AssetPositionConfigurationTab(props: AssetPositionConfigurationTabProps): JSX.Element {

    const {updateEntity, updating, error} = useUpdateEntity<AssetPosition>(
        "ASSET_POSITION", props.assetPosition.assetPositionId,
        () => {
            props.refresh && props.refresh()
        }
    )
    return (
        <BlockSpinner loading={updating}>
            <Formik
                initialValues={assetPositionToFormValues(props.assetPosition)}
                onSubmit={(v, {setSubmitting}) => {
                    setSubmitting(true);
                    updateEntity(
                        {
                            ...props.assetPosition,
                            configuration: formValuesToConfiguration(v)
                        }
                    ).then(() => {
                        setSubmitting(false)
                    })
                }}
                enableReinitialize={true}
            >{
                ({isSubmitting, isValid, dirty}) => <Form>
                    <DataProcessingConfigurationForm namespace={'dataProcessing'}/>
                    <Row noGutters>
                        <Col xs={12}>
                            <Button
                                type={"submit"}
                                color={"primary"}
                                disabled={isSubmitting || !isValid || !dirty}
                            >
                                Update
                            </Button>
                        </Col>
                        <ErrorBanner error={error}/>
                    </Row>
                </Form>
            }
            </Formik>
        </BlockSpinner>
    )
}

export default AssetPositionConfigurationTab;