import React from "react";
import { Event } from "../../openapi/model/event";
import { AssetAlarmEvent } from "../../openapi/model/assetAlarmEvent";
import { ListGroupItem } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faExclamationCircle,
  faSlash,
  faWifi,
} from "@fortawesome/free-solid-svg-icons";
import {
  formatDate,
  formatDuration,
} from "../../components/utils/HighlightNullAndOldDates";
import { GatewayAsset } from "../../openapi/model/gatewayAsset";
import moment from "moment";
import CompanyNavLink from "../../components/navigation/CompanyNavLink";
import { Link } from "react-router-dom";
import PrivacyMode from "../../components/Text/PrivacyMode";

const AlarmIcon = (props: { alarm: AssetAlarmEvent }) => {
  const closed = !(props.alarm.closedDateTime === undefined);
  switch (props.alarm.alarmType) {
    case "GATEWAY_OFFLINE":
      return (
        <span className={"fa-layers fa-fw ml-0 mr-2"}>
          <FontAwesomeIcon
            icon={faSlash}
            className={closed ? "text-success" : "text-danger"}
          />
          <FontAwesomeIcon
            icon={faWifi}
            className={closed ? "text-success" : "text-danger"}
          />
        </span>
      );
    default:
      return <FontAwesomeIcon icon={faExclamationCircle} />;
  }
};

const getAlarmHeading = (alarm: AssetAlarmEvent): JSX.Element => {
  const closed = !(alarm.closedDateTime === undefined);
  switch (alarm.alarmType) {
    case "GATEWAY_OFFLINE":
      return (
        <CompanyNavLink
          tag={Link}
          to={"/admin/asset/" + alarm.affectedObjects.asset?.assetId}
          className={closed ? "text-success" : "text-danger"}
        >
          Gateway{" "}
          <PrivacyMode>{alarm.affectedObjects.asset?.serialNumber}</PrivacyMode>{" "}
          offline
          {closed && (
            <>
              {" "}
              (closed{" "}
              {formatDuration(
                moment.duration(moment().diff(alarm.closedDateTime))
              )}
              )
            </>
          )}
        </CompanyNavLink>
      );
    default:
      return <></>;
  }
};

const AlarmMessage = (props: { alarm: AssetAlarmEvent }): JSX.Element => {
  const { alarm } = props;
  const closed = !(props.alarm.closedDateTime === undefined);
  const asset = alarm.affectedObjects.asset as GatewayAsset;
  switch (alarm.alarmType) {
    case "GATEWAY_OFFLINE":
      return (
        <>
          <span className={"d-inline-block"}>
            {alarm.eventData?.lastContact ? (
              <>
                Data was last received at&nbsp;
                {formatDate(alarm.eventData.lastContact, "LT")} on{" "}
                {formatDate(alarm.eventData.lastContact, "L")}.
              </>
            ) : closed ? (
              <>Data was not received within the expected period.</>
            ) : (
              <>
                Data was last received at&nbsp;
                {formatDate(asset.lastContact, "LT")} on{" "}
                {formatDate(asset?.lastContact, "L")}.
              </>
            )}
          </span>
          {alarm.affectedObjects.location && (
            <span className={"d-inline-block px-1"}>
              Address:{" "}
              <CompanyNavLink
                tag={Link}
                to={
                  "/admin/location/" + alarm.affectedObjects.location.locationId
                }
              >
                <PrivacyMode>
                  {alarm.affectedObjects.location.address}.
                </PrivacyMode>
              </CompanyNavLink>
              {alarm.affectedObjects.assetPosition &&
                alarm.affectedObjects.assetPosition.customerReference && (
                  <>
                    &nbsp;Reference:&nbsp;
                    <CompanyNavLink
                      tag={Link}
                      to={
                        "/admin/asset_position/" +
                        alarm.affectedObjects.assetPosition.assetPositionId
                      }
                    >
                      <PrivacyMode>
                        {alarm.affectedObjects.assetPosition.customerReference}
                      </PrivacyMode>
                    </CompanyNavLink>
                  </>
                )}
            </span>
          )}
        </>
      );
    default:
      return <></>;
  }
};

const AlarmListItemGroup = (props: {
  icon: JSX.Element;
  heading: JSX.Element;
  age: string | React.ReactNode;
  description: JSX.Element;
}): JSX.Element => {
  const { icon, heading, age, description } = props;
  return (
    <ListGroupItem className={"px-0 py-1 flex-column align-items-start"}>
      <div className="d-flex w-100 justify-content-between">
        <div>
          <div className="d-flex w-100 align-items-center">
            {icon}
            <h5 className={"mb-0"}>{heading}</h5>
          </div>
        </div>
        <small>{age}</small>
      </div>
      <p className="text-sm mb-0 mt-1">{description}</p>
    </ListGroupItem>
  );
};

const AlarmWidgetRow = (
  value: Event | AssetAlarmEvent,
  index: number
): JSX.Element => {
  if (Object.hasOwnProperty.call(value, "alarmType")) {
    const alarm = value as AssetAlarmEvent;
    return (
      <AlarmListItemGroup
        icon={<AlarmIcon alarm={alarm} />}
        heading={getAlarmHeading(alarm)}
        age={formatDuration(
          moment.duration(moment().diff(alarm.eventDateTime))
        )}
        description={<AlarmMessage alarm={alarm} />}
        key={index}
      />
    );
  } else {
    return <></>;
  }
};

export default AlarmWidgetRow;
