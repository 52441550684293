import React from 'react';
import {AssetFile} from "../../../openapi/model/assetFile";
import {Badge, UncontrolledTooltip} from "reactstrap";
import classNames from "classnames";
import {IntegrationFile} from "../../../openapi/model/integrationFile";
import {isGatewayFile} from "./GatewayFileListRow";

function GatewayFileStatusBadge(props: { file: AssetFile | IntegrationFile }) {
    const {file} = props

    const [color, text, tooltip_title, ...tooltip]: (string | undefined)[] = {
        PROCESSED: ['success', 'Processed', undefined],
        PROCESSED_WITH_ERRORS: ['warning', 'Processed (with errors)', 'File could not be fully processed because: ', ...file.processingErrors || ['Unknown errors.']],
        QUARANTINED: ['danger', 'Quarantined', 'File has been quarantined because: ', isGatewayFile(file) ? file.quarantineReason || 'Unknown.' : 'Unknown'],
        QUEUED: ['dark', 'Queued', undefined],
        UNKNOWN: ['light', 'Unknown', undefined]
    }[file.status || 'QUEUED']

    return <>
        <Badge color={color}>{text}</Badge>
        {tooltip_title && <a
            className={classNames({
                "p-1 table-action": true,
            })}
            id={`tooltip-file-error-${file.fileId}`}
        >
            <i
                className={classNames({
                    "fas fa-fw fa-circle-info": true,
                })}
            />
            <UncontrolledTooltip
                delay={0}
                target={`tooltip-file-error-${file.fileId}`}
            >
                {tooltip_title}
                <ul>
                    {tooltip.map((error, i) => <li key={i}>{error}</li>)}
                </ul>
            </UncontrolledTooltip>
        </a>
        }
    </>
}

export default GatewayFileStatusBadge;